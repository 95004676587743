import styles from "./active_indicator.module.css";

export default function ActiveIndicator({active})
{
  return (
    <>
      <span className={active ? styles.active : styles.notActive}
            title={active ? 'Присутній' : 'Відсутній'}>
      </span>
    </>
  )
}