import OAuthProvider from "../Auth/OAuthProvider";
import RouterComponent from "../router/RouterComponent";
import Loader from "../utils/loader/Loader";
import {useContext, useState} from "react";
import AppContext from './AppContext';

function App() {

  const [loading, setLoading] = useState(true)
  const context = useContext(AppContext);
  context.setLoading = setLoading;

  return (
    <AppContext.Provider value={context}>
      <OAuthProvider>
        <Loader loading={loading}/>
        <RouterComponent/>
      </OAuthProvider>
    </AppContext.Provider>
  );
}

export default App;
