import Input from "./Input";
import styles from "./loginform.module.css";
import {useState} from "react";
import apiService from '../../api/apiService';

export default function LoginForm({callback}){

  const initErrors = {
    username: null,
    password: null,
  };
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })
  const [errors, setErrors] = useState(initErrors)
  const [disabled, setDisabled] = useState(false)

  const submitForm = (e) => {
    e.preventDefault();
    const currentErrors = {
      ...initErrors
    }
    setDisabled(true);
    if (!formData.username || 3 > formData.username.length){
      currentErrors.username = 'Логін не може бути меншим за 3 символа'
    }
    if (!formData.password || 5 > formData.password.length){
      currentErrors.password = 'Пароль не може бути меншим за 5 символів'
    }
    if (!currentErrors.username && !currentErrors.password){
      apiService
        .post('/login', {
          username: formData.username,
          password: formData.password,
        })
        .then((response) => {
          console.log('login response', response);
          callback(response.data.token);
        })
        .catch(async (error) => {
          if (error instanceof Response) {
            const errorResponse = await error.json();
            console.log(errorResponse);
            if (undefined !== errorResponse.errors){
              setErrors({
                ...initErrors,
                ...errorResponse.errors
              })
            } else {
              setErrors({
                ...initErrors,
                password: errorResponse.error.description
              })
            }
          } else {
            setErrors({
              ...initErrors,
              password: 'Не вдалося відправити запит. Спробуйте пізніше.'
            })
          }
        })
        .finally(() => {
          setDisabled(false);
        })
    } else {
      setErrors(currentErrors);
      setDisabled(false);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <form onSubmit={submitForm}>
          <Input type="text"
                 name='username'
                 error={errors.username}
                 placeholder='логін'
                 value={formData.username}
                 onChange={(e) => setFormData({...formData, username: e.target.value})}
                 disabled={disabled}
          />
          <Input type="password"
                 name='password'
                 error={errors.password}
                 placeholder='пароль'
                 value={formData.password}
                 onChange={(e) => setFormData({...formData, password: e.target.value})}
                 disabled={disabled}/>
          <button type="submit"
                  className={styles.submitBtn}
                  disabled={disabled}
          >відправити</button>

        </form>
      </div>
    </div>
  )
}