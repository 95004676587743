import {useContext, useEffect, useState} from "react";
import apiService from "../../api/apiService";
import cardConstants from "../card/CardConstants";
import deckFormStyles from "./deckform.module.css";
import {Card} from "../card/Card";
import Input from "../../utils/form/Input";
import { useNavigate } from "react-router-dom";
import AppContext from "../AppContext";

export default function DeckForm({deckId, loadUrl, saveUrl, gameId})
{
  const initKit = {
    default: [],
    selected: [],
  }
  const initLeader = {
    default: [],
    selected: 0,
  }
  const initErrors = {
    name: null,
    cards: null,
    deckId: null,
    userId: null
  }
  const app = useContext(AppContext);
  const navigate = useNavigate();
  const [kit, setKit] = useState(initKit)
  const [leaders, setLeaders] = useState(initLeader)
  const [name, setName] = useState('')
  const [error, setErrors] = useState(initErrors)
  const numbers = {all:0, enemy:0, special: 0, strong: 0, leader: 0};

  useEffect(() => {
    app.setLoading(true);
    apiService
      .get(loadUrl)
      .then((response) => {
        const newCards = [];
        const newLeaders = [];
        let selectedLeader = 0;
        let leadersCounter = 0;
        response.data.kit.map(function (card){
          if (card.role === cardConstants.role.leader){
            newLeaders.push({...card});
            if (0 < card.selected){
              selectedLeader = leadersCounter;
            }
            leadersCounter++;
          } else {
            newCards.push({...card});
          }
        })
        if (response.data.name){
          setName(response.data.name)
        }
        setKit(
          {
            default: newCards,
            selected: newCards.map(function (card) {
              const cloneCard = {...card};
              cloneCard.count = card.selected;
              return cloneCard;
            }),
          });
        setLeaders({
          selected: selectedLeader,
          default: newLeaders,
        });
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }, [])

  const addToSelect = (targetCard) => {
    if ((targetCard.role === cardConstants.role.enemy && numbers.enemy < cardConstants.limit.enemy)
      || ((targetCard.role === cardConstants.role.special || targetCard.role === cardConstants.role.element
        || targetCard.role === cardConstants.role.bugle)
        && numbers.special < cardConstants.limit.special)) {
      setKit({
        default: [...kit.default].map(function (card) {
          const cloneCard = {...card};
          if (cloneCard.id === targetCard.id && cloneCard.count > 0) {
            cloneCard.count--;
          }
          return cloneCard;
        }),
        selected: [...kit.selected].map(function (card) {
          const cloneCard = {...card};
          if (cloneCard.id === targetCard.id) {
            cloneCard.count++;
          }
          return cloneCard;
        }),
      })
    }
  }

  const save = () => {
    if (numbers.special === cardConstants.limit.special && numbers.enemy === cardConstants.limit.enemy) {
      if (!name) {
        setErrors({
          ...initErrors,
          name: 'Вкажіть назву нової колоди'
        })
      } else if (name.length < 3) {
        setErrors({
          ...initErrors,
          name: 'Назва має містити не менше 3 символів'
        })
      } else {
        app.setLoading(true);
        const selectedLeader = leaders.default[leaders.selected];
        const selectedCards = [{id: selectedLeader.id, count: 1}];
        kit.selected.map(function (card) {
          if (card.count > 0){
            selectedCards.push({
              id: card.id,
              count: card.count
            })
          }
          return card
        })
        apiService
          .post(saveUrl, {
            name: name,
            deckId: deckId,
            cards: selectedCards
          })
          .then(() => {
            navigate('/choose-deck' + (gameId ? '/' + gameId : ''))
          })
          .catch(async (error) => {
            if (error instanceof Response) {
              const errorResponse = await error.json();
              if (undefined !== errorResponse.errors){
                setErrors({
                  ...initErrors,
                  ...errorResponse.errors
                })
              } else {
                alert(errorResponse.error.type + ': ' + errorResponse.error.description);
              }
            } else {
              alert('Не вдалося відправити запит. Спробуйте пізніше.');
            }
          })
          .finally(() => app.setLoading(false))
      }
    }
  }

  const removeFromSelect = (targetCard) => {
    setKit({
      default: [...kit.default].map(function (card) {
        const cloneCard = {...card};
        if (cloneCard.id === targetCard.id){
          cloneCard.count++;
        }
        return cloneCard;
      }),
      selected: [...kit.selected].map(function (card) {
        const cloneCard = {...card};
        if (cloneCard.id === targetCard.id && cloneCard.count > 0) {
          cloneCard.count--;
        }
        return cloneCard;
      }),
    })
  }

  const defaultKit = kit.default.map(function (card){
    if (card.count && cardConstants.role.leader !== card.role){
      numbers.all += card.count;
      return <Card key={card.id} card={card} handle={addToSelect} scale={true} count={true}/>
    }
  });
  const selectedKit = kit.selected.map(function (card){
    if (card.count && cardConstants.role.leader !== card.role) {
      numbers.all += card.count;
      numbers.strong += card.count * card.strong;
      if (card.role === cardConstants.role.special || card.role === cardConstants.role.element
        || card.role === cardConstants.role.bugle){
        numbers.special += card.count;
      } else {
        numbers.enemy += card.count;
      }
      return <Card key={card.id} card={card} handle={removeFromSelect} scale={true} count={true}/>
    }
  });



  return (
    <>
      <div>
        <div className={deckFormStyles.nameContainer}>
          <Input value={name} onChange={(e) => {
            setErrors({...error, name: null})
            setName(e.target.value)
          }} error={error.name} placeholder="введіть назву"/>
        </div>
        <div className={deckFormStyles.kitContainer}>
          <div className={deckFormStyles.kitField}>
            {selectedKit}
          </div>
          <div className={deckFormStyles.summary}>
            {leaders.default[leaders.selected] ?
              <div className={deckFormStyles.leader}>
                <span
                  className={deckFormStyles.leaderNav + ' ' + (leaders.selected ? null : deckFormStyles.navDisabled)}
                  onClick={() => setLeaders({
                    ...leaders,
                    selected: leaders.selected - 1
                  })}
                >&laquo;</span>
                <Card card={leaders.default[leaders.selected]} handle={() => {}}/>
                <span
                  className={deckFormStyles.leaderNav + ' ' + (leaders.selected === leaders.default.length - 1 ? deckFormStyles.navDisabled : null)}
                  onClick={() => setLeaders({
                    ...leaders,
                    selected: leaders.selected + 1
                  })}
                >&raquo;</span>
              </div> : null}
            <div className={deckFormStyles.summaryNumbers}>
              <label>карт в колоді</label>
              <span>{numbers.all}</span>
              <label>карти</label>
              <span>{numbers.enemy + ' / ' + cardConstants.limit.enemy}</span>
              <label>спеціальні</label>
              <span>{numbers.special + ' / ' + cardConstants.limit.special}</span>
              <label>сила</label>
              <span>{numbers.strong}</span>
              {cardConstants.limit.enemy === numbers.enemy && cardConstants.limit.special === numbers.special ?
                <button className={deckFormStyles.submitBtn} onClick={save}>зберегти</button>
                :
                null
              }
            </div>
          </div>
          <div className={deckFormStyles.kitField}>
            {defaultKit}
          </div>
        </div>
        <div className={deckFormStyles.error}>{error.cards} {error.deckId} {error.userId}</div>
      </div>
    </>
  )
}