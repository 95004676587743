import styles from "./new.module.css";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import apiService, {apiUrl} from '../../api/apiService';
import TopNav from "../../utils/nav/TopNav";
import AppContext from "../AppContext";

export default function DeckSelect ()
{
  const params = useParams();
  const gameId = params.id;
  const app = useContext(AppContext);
  const [decks, setDecks] = useState(null);

  useEffect(function (){
    app.setLoading(true);
    apiService
      .get('/deck/exist')
      .then((response) => {
        setDecks(response.data)
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }, []);

  const remove = (e, deckId) => {
    e.preventDefault();
    if (window.confirm('Ви впевнені, що хочете видалити колоду?')) {
      app.setLoading(true);
      apiService
        .post('/deck/delete/' + deckId)
        .then(() => {
          setDecks(decks.filter(function (deck) {
            return deck.id !== deckId
          }));
        })
        .catch(async (error) => {
          if (error instanceof Response) {
            const errorResponse = await error.json();
            alert(errorResponse.error.type + ': ' + errorResponse.error.description);
          } else {
            alert('Не вдалося відправити запит. Спробуйте пізніше.');
          }
        })
        .finally(() => app.setLoading(false))
    }
  }

  const deckLine = decks ? decks.map(function (deck){
    return <UsersDeck key={deck.id} deck={deck} remove={remove} gameId={gameId}/>
  }) : null;

  return (
    <>
      <div className="container-background">
        <h1>виберіть колоду</h1>
        <TopNav>
          <Link to={'/create-deck' + (gameId ? '/' + gameId : '')} >створити</Link>
          <Link to='/' >на головну</Link>
        </TopNav>
        <div className={styles.decksContainer}>
          {null === deckLine ?
            'Завантаження колод ...' :
            0 < deckLine.length ? deckLine : 'Для початку створіть нову колоду'
          }
        </div>
      </div>
    </>
  )
}


function UsersDeck({ deck, remove, gameId })
{
  return (
    <div className={styles.deck} >
      <Link to={'/select-card/' + deck.id + (gameId ? '/' + gameId : '')}>
        <img className={styles.deckImage} src={apiUrl + deck.src} alt={deck.name}/>
      </Link>
      <div className={styles.deckTitle}>
        {deck.title}
        <div className={styles.actionBtn}>
          <Link to={'/edit-deck/' + deck.id + (gameId ? '/' + gameId : '')} title="Редагувати"><span>&#128397;</span></Link>
          <a href={'/deck/delete/' + deck.id} onClick={(e) => remove(e, deck.id)} title="Видалити"><span>&#128465;</span></a>
        </div>
      </div>
    </div>
  );
}