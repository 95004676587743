import {Link} from "react-router-dom";

export default function GameList(){

  return (
    <>
      <h1>Стара гра</h1>
      <Link to='/' >Повернутись</Link>
    </>
  )
}