export default {
  role: {
    leader: 1,
    element: 2,
    special: 3,
    enemy: 4,
    bugle: 5
  },
  limit: {
    enemy: 22,
    special: 10,
    in: 10
  },
  line: {
    sword: 1,
    rogue: 2,
    ballista: 4,
  },
  lives: 2,
  element: {
    frost: 1,
    fog: 2,
    rain: 3,
  },
  ability: {
    magic: 1,
    revival: 2,
    replace: 16,
    execution: 256,
    element: 512,
    spy: 16384,
  }
}