import styles from './cardSelect.module.css'
import newStyles from './../deck/new.module.css'
import TopNav from "../../utils/nav/TopNav";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import apiService from "../../api/apiService";
import cardConstants from "./CardConstants";
import {Card} from "./Card";
import AppContext from "../AppContext";

export default function ()
{
  const params = useParams();
  const deckId = params.id;
  const gameId = params.game;
  const navigate = useNavigate();
  const app = useContext(AppContext);

  const initDeck = {
    title: null,
    all: [],
    leader: {},
    selected: [],
    changed: 0
  }
  const initErrors = {
    cards: null,
    deckId: null,
    userId: null
  }

  const [deck, setDecks] = useState(initDeck);
  const [error, setErrors] = useState(initErrors)

  useEffect(() => {
    app.setLoading(true);
    apiService
      .get('/deck/load/' + deckId)
      .then((response) => {
        console.log(response.data)
        const newDeck = {
          title: response.data.name,
          all: [],
          leader: {},
          selected: [],
          changed: 0
        };
        response.data.kit.map(function (card){
          for (let i = card.selected; i > 0; i--){
            if (cardConstants.role.leader === card.role) {
              newDeck.leader = {...card};
            } else {
              newDeck.all.push({
                ...card,
                index: card.id + '_' + i,
                in: false
              });
            }
          }
        })
        for (let i = 0; i < cardConstants.limit.in; i++){
          let index;
          do {
            index = Math.floor(Math.random() * newDeck.all.length);
          } while (false !== newDeck.all[index].in)
          newDeck.all[index].in = true;
          newDeck.selected.push({
            ...newDeck.all[index]
          })
        }
        setDecks(newDeck)
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }, [])

  const changeCard = (changedCard) => {
    if (!changeAllowed()){
      alert('Ви вже змінили 2 карти. Розпочинайте гру.')
      return;
    }
    let index;
    do {
      index = Math.floor(Math.random() * deck.all.length);
    } while (false !== deck.all[index].in)
    const newCard = deck.all[index];
    const newSelected = deck.selected.filter((card) => card.index !== changedCard.index);
    setDecks({
      ...deck,
      changed: deck.changed + 1,
      all: deck.all.map((card) => {
          if (changedCard.index === card.index){
            card.in = false;
          }
          if (newCard.index === card.index){
            card.in = true;
          }
          return card;
        }),
      selected: [
        ...newSelected,
        {
          ...newCard,
          in: true
        }
      ]
    })
  }

  const changeAllowed = () => deck.changed < 2;

  const selectedCards = deck.selected.map(function (card){
    return (
      <div key={card.index} className={styles.cardContainer + ' ' + (changeAllowed() ? null : styles.changedDisabled)}>
        <Card card={card} handle={() => changeCard(card)} scale={changeAllowed()}/>
      </div>
    )
  });

  const start = () => {
    app.setLoading(true);
    const selectedCards = [{
      id: deck.leader.id,
      in: true
    }];
    deck.all.map(function (card) {
      selectedCards.push({
        id: card.id,
        in: card.in
      })
    })
    apiService
      .post(gameId ? ('/game/join/' + gameId + '/' + deckId) : ('/game/create/' + deckId), {
        cards: selectedCards
      })
      .then((response) => {
        console.log(response);
        navigate('/game/' + response.data.id)
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          if (undefined !== errorResponse.errors){
            setErrors({
              ...initErrors,
              ...errorResponse.errors
            })
          } else {
            alert(errorResponse.error.type + ': ' + errorResponse.error.description);
          }
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }

  return (
    <>
      <div className="container-background">
        <h1>виберіть карти для гри</h1>
        <TopNav>
          <Link to={'/choose-deck' + (gameId ? '/' + gameId : '')} >назад</Link>
          <Link to='/' >на головну</Link>
        </TopNav>
        <div className={styles.wrapper}>
          <div className={newStyles.deckTitle + ' ' + styles.title}>
            {deck.title}
            <div className={newStyles.actionBtn + ' ' + styles.actionBtn}>
              <Link to={'/edit-deck/' + deckId + (gameId ? '/' + gameId : '')} title="Редагувати"><span>&#128397;</span></Link>
            </div>
          </div>
          <div className={styles.container}>
            {selectedCards}
          </div>
          <button className={styles.submitBtn} onClick={start}>{gameId ? 'приєднатися' : 'почати гру'}</button>
          <div className={styles.error}>{error.cards} {error.deckId} {error.userId}</div>
        </div>
      </div>
    </>
  )
}