import styles from './loader.module.css';

export default function Loader({ loading })
{
  return (
    <>
      {
        loading &&
          <div className={styles.container}>
            <div className={styles.spinner}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
      }
    </>
  )
}