import React, {useContext, useEffect, useState} from 'react'
import AuthContext from './AuthContext'
import apiService from "../api/apiService";
import AppContext from "../pages/AppContext";
import Pusher from 'pusher-js';

function OAuthProvider({children}) {

  const app = useContext(AppContext);
  const [isGuest, setIsGuest, ] = useState(null);
  const [websocket, setWebsocket] = useState(null);
  const [activeMembers, setMembers] = useState({list: [], me: {}})

  useEffect(() => {
    apiService
      .post('/check', {})
      .then((response) => {
        console.log('check auth response', response);
        setGuest(401 === response.statusCode, response.data.token || null)
      })
      .catch(async () => {
        console.log('in check auth catch');
        setGuest(true)
      })
      .finally(() => {
        app.setLoading(false);
      })
  },[]
)

  const pusherHandler = async (token) => {

    Pusher.logToConsole = process.env.NODE_ENV === 'development';
    const newWs = new Pusher(process.env.REACT_APP_PUSHER_API_KEY,
      {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        userAuthentication: {
          endpoint: apiService.host + '/pusher-signin-auth',
          params: {token: token},
        },
        channelAuthorization: {
          endpoint: apiService.host + '/pusher-channel-auth'
        }
        // encrypted: true
      }
    );
    newWs.connection.bind('disconnected', logout)

    newWs.signin();


    newWs.bind('pusher:signin_success', (event) => {
      console.log('пользователь успешно залогинен', event);
      setIsGuest(false)
    })

    const membersChannel = newWs.subscribe('presence-members');
    membersChannel.bind("pusher:subscription_succeeded", () => {
      const allMembers = [];
      const me = membersChannel.members.me;
      membersChannel.members.each((member) => allMembers.push({...member}))
      setMembers(() => {
        return {
          list: allMembers,
          me: me
        }
      })
    });

    membersChannel.bind("pusher:member_added", (member) => {
      setMembers((members) => {
        return {
          me: {...members.me},
          list: [
            ...members.list,
            {...member}
          ]
        }
      })
    });

    membersChannel.bind("pusher:member_removed", (removedMember) => {
      setMembers((members) => {
        return {
          me: {...members.me},
          list: members.list.filter((member) => member.id !== removedMember.id)
        }
      });
    });

    setWebsocket(newWs)

    return () => websocket.unsubscribe('presence-members');
  }

  const setGuest = (mode, token) => {
    console.log('setGuest', mode, token)
    if (false === mode){
      if (!websocket) {
        pusherHandler(token)
      }
    } else if (false === isGuest) {
      logout()
    } else {
      setIsGuest(mode)
      if ('/' !== window.location.pathname) {
        window.location.href = '/'
      }
    }
  }

  const logout = (callback) => {
    if (false === isGuest) {
      app.setLoading(true);
      apiService
        .post('/logout', {})
        .then(() => {
          setIsGuest(true);
          if (websocket) {
            setWebsocket(null)
          }
          if ('/' !== window.location.pathname) {
            window.location.href = '/'
          }
        })
        .catch(async () => {
          alert('Не вдалося розлогінтися')
        })
        .finally(() => {
          app.setLoading(false);
          if ('function' === typeof callback) {
            callback();
          }
        })
    }
  }

  const contextValue = {
    isGuest: isGuest,
    setGuest: setGuest,
    logout: logout,
    websocket: websocket,
    members: activeMembers
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default OAuthProvider
