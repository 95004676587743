
import TopNav from "../../utils/nav/TopNav";
import {Link, useParams} from "react-router-dom";
import styles from "./new.module.css";
import DeckForm from "./DeckForm";


export default function DeckEdit()
{
  const params = useParams();
  const deckId = params.id;
  const gameId = params.game;

  return (
    <>
      <div className="container-background">
        <h1>Редагування колоди</h1>
        <TopNav>
          <Link to={'/choose-deck' + (gameId ? '/' + gameId : '')} >назад</Link>
          <Link to='/' >на головну</Link>
        </TopNav>
        <div className={styles.decksContainer}>
          <DeckForm
            gameId={gameId}
            deckId={deckId}
            loadUrl={'/deck/load/' + deckId}
            saveUrl={'/deck/edit/' + deckId}
          />
        </div>
      </div>
    </>
  )
}