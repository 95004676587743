import styles from "./new.module.css";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import apiService, {apiUrl} from '../../api/apiService';
import DeckForm from "./DeckForm";
import TopNav from "../../utils/nav/TopNav";
import AppContext from "../AppContext";

export default function DeckCreate ()
{
  const params = useParams();
  const gameId = params.id;
  const app = useContext(AppContext);
  const [decks, setDecks] = useState([]);
  const [deckId, setDeckId] = useState(null)

  useEffect(function (){
    console.log('call NewDeck useEffect');
    app.setLoading(true);
    apiService
      .get('/deck/list')
      .then((response) => {
        console.log('before setDecks in NewDeck');
        console.log(response);
        setDecks(response.data)
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }, []);


  const loadCards = (id) => {
    setDeckId(id)
  }

  const reset = () => {
    setDeckId(null)
  }

  const deckLine = decks.map(function (deck){
    return <Deck key={deck.id} deck={deck} loadCards={loadCards}/>
  })

  return (
    <>
      <div className="container-background">
        <h1>Нова колода</h1>
        <TopNav>
          { deckId ?
            <a onClick={reset}>назад</a>
            :
            <Link to={'/choose-deck' + (gameId ? '/' + gameId : '')} >назад</Link>
          }
          <Link to='/' >на головну</Link>
        </TopNav>
        <div className={styles.decksContainer}>
          {deckId ?
            <DeckForm
              gameId={gameId}
              deckId={deckId}
              loadUrl={'/deck/kit/' + deckId}
              saveUrl={'/deck/create'}
            />
            : deckLine}
        </div>
      </div>
    </>
  )
}


function Deck({ deck, loadCards })
{
  return (
    <div className={styles.deck} >
      <div className={styles.deckTitle}>{deck.title}</div>
      <img className={styles.deckImage} src={apiUrl + deck.src} alt={deck.name} onClick={() => loadCards(deck.id)}/>
    </div>
  );
}