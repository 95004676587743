import isJsonResponse from './isJsonResponse'

export const apiUrl = process.env.REACT_APP_API_URL;

function request(url, method, data, headers) {
  const common = {
    method,
    credentials: "include",
    headers: {
      Accept: 'application/json',
      ...headers,
    },
  }

  const body =
    data !== null
      ? {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
      : { headers: {} }

  return fetch(apiUrl + url, {
      ...common,
      ...body,
      headers: {
        ...common.headers,
        ...(body.headers),
      },
    })
    .then((response) => {
      if ('/check' === url){
        return response;
      }
      if (401 === response.status){
        window.location.href = '/';
      }
      if (response.ok) {
        return response
      }
      throw response
    })
    .then((response) => {
      if (isJsonResponse(response)) {
        return response.json()
      }
      return response.text()
    })
}

const apiService = {
  get: (url, headers) => request(url, 'GET', null, headers),
  post: (url, data, headers) =>
    request(url, 'POST', data, headers),
  host: apiUrl
}

export default apiService
