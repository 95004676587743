
export default function Lives({lives, className})
{
  const getPoints = (lives, className) => {
    const points = [];
    for (let i = 0; i < lives; i++){
      points.push(<img key={className + i} src='/point.png' className={className} alt='point'/>);
    }
    return points;
  }

  return getPoints(lives, className)
}