import styles from './input.module.css';

export default function Input({hint, error, ...props}){

  // const attributes = [...props];
  // attributes.hint = attributes.hint || null;
  // attributes.error = attributes.error || null;

  return (
    <div className={error ? styles.hasError : ''}>
      <input {...props}/>
      {hint ? (<div className={styles.hintBlock}>
        {hint}
      </div>) : null}
      {error ? (<div className={styles.errorBlock}>
        {error}
      </div>) : null}
    </div>
  )
}