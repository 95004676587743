import {RouterProvider} from "react-router-dom";
import React from "react";
import userRoutes from "./UserRoutes";

export default function RouterComponent() {

  const router = userRoutes;

  return (
    <RouterProvider router={router} />
  )
}