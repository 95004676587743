import Home from "../pages/Home";
import ErrorPage from "../pages/Error";
import DeckCreate from "../pages/deck/DeckCreate";
import DeckSelect from "../pages/deck/DeckSelect";
import DeckEdit from "../pages/deck/DeckEdit";
import Game from "../pages/game/Game";
import GameList from "../pages/game/GameList";
import {createBrowserRouter} from "react-router-dom";
import CardSelect from "../pages/card/CardSelect";
import Dashboard from "../pages/Dashboard";

const routes = [
  {
    path: "/",
    element: <Home/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "choose-deck/:id?",
    element: <DeckSelect/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "create-deck/:id?",
    element: <DeckCreate/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "edit-deck/:id/:game?",
    element: <DeckEdit/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "select-card/:id/:game?",
    element: <CardSelect/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "game/:id",
    element: <Game/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "game/list",
    element: <GameList/>,
    errorElement: <ErrorPage />,
  },
];
export default createBrowserRouter(routes);