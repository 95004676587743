import TopNav from "../utils/nav/TopNav";
import {Link} from "react-router-dom";
import styles from "./deck/new.module.css";
import dashboardStyles from "./dashboard.module.css";
import {useContext, useEffect, useState} from "react";
import apiService, {apiUrl} from "../api/apiService";
import AppContext from "./AppContext";
import AuthContext from "../Auth/AuthContext";
import ActiveIndicator from "../utils/indicator/ActiveIndicator";
import Lives from "./game/Lives";


export default function Dashboard()
{
  return (
    <>
      <div className="container-background">
        <h1>Виберіть гру</h1>
        <TopNav>
          <Link to='/choose-deck' >створити</Link>
        </TopNav>
        <div className={styles.decksContainer}>
          <GameTable/>
        </div>
      </div>
    </>
  );
}

function GameTable()
{
  const app = useContext(AppContext);
  const auth = useContext(AuthContext);
  const [games, setGames] = useState(null);

  useEffect(() => {
    if (auth.websocket) {
      loadGames();
      const gameListChannel = auth.websocket.subscribe('private-game-list');
      gameListChannel.bind('list:changed', (data) => {
        console.log('list:changed handler')
        if (Number(data.user) !== Number(auth.members.me.id)){
          console.log('init load games', data.user, auth.members.me.id, auth.members)
          loadGames()
        }
      });
      return () => {
        auth.websocket.unsubscribe('private-game_list');
      }
    }
  }, [auth.websocket])

  const loadGames = (callback) => {
    app.setLoading(true);
    apiService
      .get('/game/list')
      .then((response) => {
        console.log(response);
        setGames(response.data.list)
        if ('function' === typeof callback){
          callback();
        }
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
          console.log('error: ', error);
        }
      })
      .finally(() => app.setLoading(false))
  }

  const remove = (e, gameId) => {
    e.preventDefault();
    if (window.confirm('Ви впевнені, що хочете видалити гру?')) {
      app.setLoading(true);
      apiService
        .post('/game/delete/' + gameId)
        .then(() => {
          setGames(games.filter(function (game) {
            return game.id !== gameId
          }));
        })
        .catch(async (error) => {
          if (error instanceof Response) {
            const errorResponse = await error.json();
            alert(errorResponse.error.type + ': ' + errorResponse.error.description);
          } else {
            alert('Не вдалося відправити запит. Спробуйте пізніше.');
          }
        })
        .finally(() => app.setLoading(false))
    }
  }

  return(
    <>
      {
        games ?
          (0 === games.length ?
            'Немає доступних ігор'
            :
            <table className={dashboardStyles.gameTable}>
              <thead>
              <tr>
                <th>дата</th>
                <th colSpan={3}>гравець 1</th>
                <th colSpan={3}>гравець 2</th>
                <th>опції</th>
              </tr>
              </thead>
              <tbody>
                {games.map(function (game){
                  const timeStamp = game['created'] * 1000;
                  const date = new Date(timeStamp);
                  const usersInGame = auth.members.list.map(member => member.info.name);
                  return (
                    <tr key={game.id}>
                      <td>{date.toLocaleString('uk-UA')}</td>
                      <td>
                        {game.user1 && <UserCellContent user={game.user1} inGame={-1 !== usersInGame.indexOf(game.user1)}/>}
                      </td>
                      <td>
                        {game.user1 && <Lives lives={game.lives1 || 0} className={dashboardStyles.live}/>}
                      </td>
                      <td className={dashboardStyles.deckColumn}>
                        {game.deck1 &&
                          <img className={dashboardStyles.deck} src={apiUrl + game.deck1.src} alt="deck1" title={game.deck1.title}/>}
                      </td>
                      <td>
                        {game.user2 && <UserCellContent user={game.user2} inGame={-1 !== usersInGame.indexOf(game.user2)}/>}
                      </td>
                      <td>
                        {game.user2 && <Lives lives={game.lives2 || 0} className={dashboardStyles.live}/>}
                      </td>
                      <td className={dashboardStyles.deckColumn}>
                        {game.deck2 &&
                          <img className={dashboardStyles.deck} src={apiUrl + game.deck2.src} alt="deck2" title={game.deck2.title}/>}
                      </td>
                      <td>
                        {game.finished ?
                            <Link to={'/game/' + game.id} className={dashboardStyles.play} title='Подивитись'>
                              &#128065;
                            </Link>
                          :
                            <Link to={game.member ? '/game/' + game.id : '/choose-deck/' + game.id}
                                  className={dashboardStyles.play} title='Грати'>
                              &#9876;
                            </Link>
                        }
                        {game.owner &&
                          <a href={'/game/delete/' + game.id} className={dashboardStyles.play}
                             onClick={(e) => remove(e, game.id)} title='Видалити'>
                            &#128465;
                          </a>
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>

              </tfoot>
            </table>
          )
        :
        'Завантажуємо ігри ...'
      }
    </>
  )
}

function UserCellContent({user, inGame})
{
  return (
    <>
      <ActiveIndicator active={inGame}/>
      {user}
    </>
  )
}