import styles from './topnav.module.css';
import NavBtn from "../buttons/NavBtn";
import {useContext, useState} from "react";
import AuthContext from "../../Auth/AuthContext";

export default function TopNav({ children })
{
  const auth = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false)

  const handle = (e) => {
    e.preventDefault();
    setDisabled(true)
    auth.logout(() => {
      setDisabled(false)
    });
  }

  return (
    <>
      <div className={styles.container}>
        { children }
        <NavBtn href="" className={styles.link} onClick={handle} disabled={disabled}>Вихід</NavBtn>
      </div>
    </>
  )
}