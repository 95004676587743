import styles from "./game.module.css";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import apiService, {apiUrl} from "../../api/apiService";
import cardConstants from "../card/CardConstants";
import {Card} from "../card/Card";
import AuthContext from "../../Auth/AuthContext";
import AppContext from "../AppContext";
import ActiveIndicator from "../../utils/indicator/ActiveIndicator";
import Lives from "./Lives";

export default function Game()
{
  const app = useContext(AppContext);
  const auth = useContext(AuthContext);
  const params = useParams();
  const gameId = params.id;
  const initData = {
    userId: null,
    winner: null,
    userTurn: false,
    hold: false,
    livesOwn: 0,
    livesEnemy: 0,
    ownDeck: {},
    enemyDeck: {},
    counts: {
      own: {
        count: 0,
        sword: 0,
        rogue: 0,
        ballista: 0,
      },
      enemy: {
        count: 0,
        sword: 0,
        rogue: 0,
        ballista: 0,
      }
    },
    kit: []
  };
  const initErrors = {

  }
  const [gameData, setGameData] = useState(initData)
  const [members, setMembers] = useState([])
  const [error, setErrors] = useState(initErrors)
  let selectedCard = null;

  const loadGameData = (callback) => {
    app.setLoading(true);
    apiService
      .get('/game/board/' + gameId)
      .then((response) => {
        if ('function' === typeof callback){
          callback(response);
        }
        return response
      })
      .then((response) => {
        console.log(response);
        setGameData({
          userId: response.data.userId,
          winner: response.data.winner,
          userTurn: response.data.userTurn,
          hold: response.data.hold,
          ownDeck: response.data.ownDeck || {},
          enemyDeck: response.data.enemyDeck || {},
          counts: response.data.counts,
          livesOwn: response.data.livesOwn,
          livesEnemy: response.data.livesEnemy,
          kit: response.data.kit || []
        })
      })
      .catch(async (error) => {
        if (error instanceof Response) {
          const errorResponse = await error.json();
          alert(errorResponse.error.type + ': ' + errorResponse.error.description);
        } else {
          alert('Не вдалося відправити запит. Спробуйте пізніше.');
        }
      })
      .finally(() => app.setLoading(false))
  }

  useEffect(() => {
    if (auth.websocket) {
      loadGameData((response) => {
        const gameMemberChannel = auth.websocket.subscribe('presence-game_' + gameId);
        gameMemberChannel.bind("pusher:subscription_succeeded", () => {
          console.log('member was subsribed to presece game channel')
          const allMembers = [];
          gameMemberChannel.members.each((member) => allMembers.push(member))
          setMembers(() => {
            return allMembers
          })
        });
        gameMemberChannel.bind("pusher:member_added", (member) => {
          console.log('member was added', member);
          if (Object.keys(response.data.enemyDeck).length === 0){
            loadGameData();
          }
          setMembers((members) => {
            return [
              ...members,
              member
            ]
          })
        });
        gameMemberChannel.bind("pusher:member_removed", (removedMember) => {
          console.log('member was removed', removedMember);
          setMembers((members) => {
            console.log('before removed members', members);
            const filtered = members.filter((member) => removedMember.id !== member.id)
            console.log('after removed members', filtered);
            return filtered
          });
        });
        const gameChannel = auth.websocket.subscribe('private-game_' + gameId + '_' + response.data.userId);
        gameChannel.bind('game:turn', (data) => {
          refreshKit(data)
        });
      })
      return () => {
        console.log('member was unsubsribed from game channels');
        auth.websocket.unsubscribe('presence-game_' + gameId);
        auth.websocket.unsubscribe('private-game_' + gameId + '_' + gameData.userId);
      }
    }
  }, [auth.websocket])

  const refreshKit = (data) => {
    setGameData((old) => {
      const newKit = [...old.kit].map(function (oldCard){
        const card = {...oldCard}
        if (data.kit[card.id]){
          Object.keys(data.kit[card.id]).map(function (attribute){
            card[attribute] = data.kit[card.id][attribute];
          })
        }
        return card;
      })
      return {
        userId: data.userId,
        userTurn: data.userTurn,
        winner: data.winner,
        hold: data.hold,
        ownDeck: {...old.ownDeck},
        enemyDeck: {...old.enemyDeck},
        livesOwn: data.livesOwn,
        livesEnemy: data.livesEnemy,
        counts: data.counts,
        kit: newKit
      }
    })
  }

  const turnHandler = (card, e) => {
    console.log(card);
    if (e.target.classList.contains(styles.activeCard)) {
      const containsWaiting = e.target.classList.contains(styles.waiting);
      resetWaiting()
      if (!containsWaiting) {
        console.log(card)
        if (card.ability & cardConstants.ability.revival){
          document.querySelector('.' + styles.out).style.display = 'block';
        } else {
          e.target.classList.add(styles.waiting)
          if (card.ability & cardConstants.ability.replace) {
            setActiveToElements('.' + styles.canReplace, styles.activeReplaceCard);
          } else if (card.role === cardConstants.role.enemy) {
            if (card.line & cardConstants.line.sword) {
              if (card.ability & cardConstants.ability.spy){
                setActiveToElements('.' + styles.enemySword);
              } else {
                setActiveToElements('.' + styles.ownSword);
              }
            }
            if (card.line & cardConstants.line.rogue) {
              if (card.ability & cardConstants.ability.spy){
                setActiveToElements('.' + styles.enemyRogue);
              } else {
                setActiveToElements('.' + styles.ownRogue);
              }
            }
            if (card.line & cardConstants.line.ballista) {
              if (card.ability & cardConstants.ability.spy){
                setActiveToElements('.' + styles.enemyBallista);
              } else {
                setActiveToElements('.' + styles.ownBallista);
              }
            }
          } else if (card.role === cardConstants.role.element || card.ability & cardConstants.ability.execution) {
            setActiveToElements('.' + styles.elements);
          } else if (card.role === cardConstants.role.bugle) {
            setActiveToElements('.' + styles.ownBallistaBugle);
            setActiveToElements('.' + styles.ownRogueBugle);
            setActiveToElements('.' + styles.ownSwordBugle);
          }
        }
        selectedCard = card;
      }
    }
  }

  const setActiveToElements = (selector, className) => {
    className = className || styles.activeLine;
    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++){
      elements[i].classList.add(className)
    }
  }

  const resetWaiting = () => {
    const exists = document.querySelectorAll('.' + styles.waiting + ',.' + styles.activeLine
      + ',.' + styles.activeReplaceCard)
    for (let i = 0; i < exists.length; i++) {
      exists[i].classList.remove(styles.waiting)
      exists[i].classList.remove(styles.activeLine)
      exists[i].classList.remove(styles.activeReplaceCard)
    }
    closeOut();
    selectedCard = null;
  }

  const closeOut = () => {
    document.querySelector('.' + styles.out).style.display = 'none'
  }

  const selectLine = (params) => {
    if (selectedCard){
      makeTurn(params);
      resetWaiting();
    }
  }

  const makeTurn = (params) => {
    if (gameData.userTurn){
      app.setLoading(true);
      apiService
        .post('/game/turn/' + gameId,
          {cardId: selectedCard.id, ...params})
        .then((response) => {
          refreshKit(response.data);
        })
        .catch(async (error) => {
          if (error instanceof Response) {
            const errorResponse = await error.json();
            console.log(errorResponse);
            if (undefined !== errorResponse.errors){
              alert(errorResponse.errors.cardId);
              setErrors({
                ...initErrors,
                ...errorResponse.errors
              })
            } else {
              alert(errorResponse.error.type + ': ' + errorResponse.error.description);
            }
          } else {
            alert('Не вдалося відправити запит. Спробуйте пізніше.');
          }
        })
        .finally(() => app.setLoading(false))
    }
  }

  const holdHandler = () => {
    if (gameData.userTurn) {
      app.setLoading(true);
      apiService
        .post('/game/hold/' + gameId)
        .then((response) => {
          refreshKit(response.data);
        })
        .catch(async (error) => {
          if (error instanceof Response) {
            const errorResponse = await error.json();
            console.log(errorResponse);
            if (undefined !== errorResponse.errors) {
              alert(errorResponse.errors.turn);
              setErrors({
                ...initErrors,
                ...errorResponse.errors
              })
            } else {
              alert(errorResponse.error.type + ': ' + errorResponse.error.description);
            }
          } else {
            alert('Не вдалося відправити запит. Спробуйте пізніше.');
          }
        })
        .finally(() => app.setLoading(false))
    }
  }

  const ownLeader = [];
  const enemyLeader = [];
  const ownCards = [];
  const ownBallista = [];
  const ownSword = [];
  const ownRogue = [];
  const enemyCards = [];
  const enemyBallista = [];
  const enemySword = [];
  const enemyRogue = [];
  const ownOut = [];
  const enemyOut = [];
  const elements = [];
  const elementStyles = {
    backgroundRain: '',
    backgroundFog: '',
    backgroundFrost: '',
  }
  const ownBallistaBugle = [];
  const ownSwordBugle = [];
  const ownRogueBugle = [];
  const enemyBallistaBugle = [];
  const enemySwordBugle = [];
  const enemyRogueBugle = [];

  gameData.kit.map(function (card) {
    const isUsersCard = card.userId === gameData.userId;
    if (card.role === cardConstants.role.leader) {
      if (card.on && card.ability & cardConstants.ability.element){
        switch (card.line) {
          case cardConstants.line.sword:
            elementStyles.backgroundFrost = styles.frost;
            break;
          case cardConstants.line.ballista:
            elementStyles.backgroundFog = styles.fog;
            break;
          case cardConstants.line.rogue:
            elementStyles.backgroundRain = styles.rain;
            break;
        }
      }
      if (isUsersCard) {
        ownLeader.push(
          <div key={card.id}>
            <div key={card.id} className={styles.leaderOwn + ' ' + styles.card + ' ' + styles.cardOwn}>
              <Card card={card} className={(card.on && styles.leaderOut)
                + ' ' + (gameData.userTurn && (styles.activeLeader + ' ' + styles.activeCard))}
                scale={true} onClick={() => {
                  selectedCard = card;
                  makeTurn({})
                }
              }/>
            </div>
            <div className={styles.activeOwnIndicator}><ActiveIndicator
              active={members.find((member) => Number(member.id) === card.userId)}/></div>
          </div>
        );
      } else {
        enemyLeader.push(
          <div  key={card.id}>
            <div className={styles.leaderEnemy + ' ' + styles.card + ' ' + styles.cardEnemy}>
              <Card card={card} className={!gameData.userTurn && styles.activeLeader} scale={true}/>
            </div>
            <div className={styles.activeEnemyIndicator}><ActiveIndicator
              active={members.find((member) => Number(member.id) === card.userId)}/></div>
          </div>
        );
      }
    } else if (card.in) {
      if (isUsersCard) {
        ownCards.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
          <Card
            card={card}
            className={gameData.userTurn && styles.activeCard}
            handle={turnHandler}
            scale={true}
          />
        </div>);
      } else {
        enemyCards.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
          <Card card={card} className={!gameData.userTurn && styles.activeCard} scale={true}/>
        </div>);
      }
    } else if (card.out && card.role === cardConstants.role.enemy && !(card.ability & cardConstants.ability.magic)
      && !(card.ability & cardConstants.ability.execution)) {
      if (isUsersCard) {
        ownOut.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
          <Card card={card} className={gameData.userTurn && styles.activeCard} scale={true}
              onClick={(e) => {
                e.stopPropagation()
                selectLine({targetId: card.id})}
              }
          />
        </div>);
      } else {
        enemyOut.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
          <Card card={card} className={!gameData.userTurn && styles.activeCard} scale={true}/>
        </div>);
      }
    } else if (card.on) {
      if (card.role === cardConstants.role.element){
        elements.push(<div key={card.id} className={styles.card + ' ' + styles.cardElement}>
          <Card card={card} scale={false}/></div>)
        switch (card.line){
          case cardConstants.line.sword:
            elementStyles.backgroundFrost = styles.frost;
            break;
          case cardConstants.line.ballista:
            elementStyles.backgroundFog = styles.fog;
            break;
          case cardConstants.line.rogue:
            elementStyles.backgroundRain = styles.rain;
            break;
        }
      } else {
        switch (card.line) {
          case cardConstants.line.sword :
            if (card.role === cardConstants.role.bugle){
              isUsersCard ?
                ownSwordBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemySwordBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            } else {
              isUsersCard ?
                ownSword.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn
                  + (card.ability & cardConstants.ability.magic ? '' : ' ' + styles.canReplace)}
                 onClick={(e) => {
                   e.stopPropagation()
                   selectLine({targetId: card.id})}
                 }
                >
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemySword.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            }
            break;
          case cardConstants.line.rogue :
            if (card.role === cardConstants.role.bugle){
              isUsersCard ?
                ownRogueBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemyRogueBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            } else {
              isUsersCard ?
                ownRogue.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn
                  + (card.ability & cardConstants.ability.magic ? '' : ' ' + styles.canReplace)}
                 onClick={(e) => {
                   e.stopPropagation()
                   selectLine({targetId: card.id})}
                 }
                >
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemyRogue.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            }
            break;
          case cardConstants.line.ballista :
            if (card.role === cardConstants.role.bugle){
              isUsersCard ?
                ownBallistaBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemyBallistaBugle.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            } else {
              isUsersCard ?
                ownBallista.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn
                  + (card.ability & cardConstants.ability.magic ? '' : ' ' + styles.canReplace)}
                  onClick={(e) => {
                    e.stopPropagation()
                    selectLine({targetId: card.id})}
                  }
                >
                  <Card card={card} scale={true}/>
                </div>)
                :
                enemyBallista.push(<div key={card.id} className={styles.card + ' ' + styles.cardOwn}>
                  <Card card={card} scale={true}/>
                </div>)
            }
            break;
        }
      }
    }
  })

  return (
    <>
      <div className="container-background">
        <div className={styles.board}>
          <div>
            <Link to='/' className={styles.link}>x</Link>
            <img src='/board600.jpg?v=1' className={styles.img} alt="дошка для гри"/>
            <div><Lives lives={gameData.livesOwn} className={styles.pointOwn}/></div>
            <div><Lives lives={gameData.livesEnemy} className={styles.pointEnemy}/></div>
            <div className={styles.deckOwnIcon} ><span>{ownCards.length} x</span><img src='/deck.png' alt='deck'/></div>
            <div className={styles.deckEnemyIcon} ><span>{enemyCards.length} x</span><img src='/deck.png' alt='deck'/></div>
            <div className={styles.countOwn} ><span>{gameData.counts.own.count}</span></div>
            <div className={styles.countEnemy} ><span>{gameData.counts.enemy.count}</span></div>
            <div className={styles.countLineOwnSword} ><span>{gameData.counts.own.sword}</span></div>
            <div className={styles.countLineOwnRogue} ><span>{gameData.counts.own.rogue}</span></div>
            <div className={styles.countLineOwnBallista} ><span>{gameData.counts.own.ballista}</span></div>
            <div className={styles.countLineEnemySword} ><span>{gameData.counts.enemy.sword}</span></div>
            <div className={styles.countLineEnemyRogue} ><span>{gameData.counts.enemy.rogue}</span></div>
            <div className={styles.countLineEnemyBallista} ><span>{gameData.counts.enemy.ballista}</span></div>
            {ownLeader}
            {enemyLeader}
            {gameData.ownDeck.id && <div className={styles.deckOwn + ' ' + styles.card}>
              <img src={apiUrl + gameData.ownDeck.src} alt={gameData.ownDeck.title}/>
            </div>}
            {gameData.enemyDeck.id && <div className={styles.deckEnemy + ' ' + styles.card}>
              <img src={apiUrl + gameData.enemyDeck.src} alt={gameData.enemyDeck.title}/>
            </div>}
            {0 < ownOut.length && gameData.ownDeck.id && <div className={styles.dropOwn + ' ' + styles.card}>
              <img src={apiUrl + gameData.ownDeck.src} alt='drop'/>
            </div>}
            {0 < enemyOut.length && gameData.enemyDeck.id && <div className={styles.dropEnemy + ' ' + styles.card}>
              <img src={apiUrl + gameData.enemyDeck.src} alt='drop'/>
            </div>}
            <div className={styles.enemyBallista + ' ' + elementStyles.backgroundFog}
                 onClick={() => selectLine({})}>
              {enemyBallista}
            </div>
            <div className={styles.enemyRogue + ' ' + elementStyles.backgroundRain}
                 onClick={() => selectLine({})}>
              {enemyRogue}
            </div>
            <div className={styles.enemySword + ' ' + elementStyles.backgroundFrost}
                 onClick={() => selectLine({})}>
              {enemySword}
            </div>
            <div className={styles.ownSword + ' ' + elementStyles.backgroundFrost}
                 onClick={() => selectLine({line: cardConstants.line.sword})}>
              {ownSword}
            </div>
            <div className={styles.ownRogue + ' ' + elementStyles.backgroundRain}
                 onClick={() => selectLine({line: cardConstants.line.rogue})}>
              {ownRogue}
            </div>
            <div className={styles.ownBallista + ' ' + elementStyles.backgroundFog}
                 onClick={() => selectLine({line: cardConstants.line.ballista})}>
              {ownBallista}
            </div>
            <div className={styles.ownBallistaBugle} onClick={() => selectLine({line: cardConstants.line.ballista})}>
              {ownBallistaBugle}
            </div>
            <div className={styles.ownRogueBugle} onClick={() => selectLine({line: cardConstants.line.rogue})}>
              {ownRogueBugle}
            </div>
            <div className={styles.ownSwordBugle} onClick={() => selectLine({line: cardConstants.line.sword})}>
              {ownSwordBugle}
            </div>
            <div className={styles.enemyBallistaBugle}>
              {enemyBallistaBugle}
            </div>
            <div className={styles.enemyRogueBugle}>
              {enemyRogueBugle}
            </div>
            <div className={styles.enemySwordBugle}>
              {enemySwordBugle}
            </div>
            <div className={styles.kit}>
              {ownCards}
            </div>
            <div className={styles.elements} onClick={() => selectLine({})}>
              {elements}
            </div>
            {gameData.userTurn && <div className={styles.skipBtn} onClick={holdHandler} title='Закінчити раунд'>
              &#127987;
            </div>}
            {!gameData.userTurn && gameData.hold && <div className={styles.skipFlag1} title='Закінчив раунд'>
              &#127987;
            </div>}
            {gameData.userTurn && gameData.hold  && <div className={styles.skipFlag2} title='Закінчив раунд'>
              &#127987;
            </div>}
            <div className={styles.out}>
              <div>{ownOut}</div>
              <span className={styles.outClose} onClick={closeOut}>&times;</span>
            </div>
            {null !== gameData.winner && <EndGame data={gameData}/>}
          </div>
        </div>
      </div>
    </>
  )
}


function EndGame({data})
{
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setShow(false), 10000)
    return () => clearInterval(timer)
  }, [])

  return (
    <>
      {show &&
      <div className={styles.gameEndContainer}>
        <div>
          <h1>Гру завершено!</h1>
          <p className={0 === data.winner ? styles.draw : (data.userId === data.winner ? styles.win : styles.lose)}>
            {0 === data.winner ? 'Hічия' : (data.userId === data.winner ? 'Ви перемогли!' : 'Ви програли')}
          </p>
        </div>
      </div>}
    </>
  )
}