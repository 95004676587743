import cardStyles from "./card.module.css";
import {apiUrl} from "../../api/apiService";
import {useState} from "react";


export function Card({ card, handle, count, scale, className, ...props})
{
  const [showInfo, setShowInfo] = useState(false)
  const classLine = cardStyles.img + (className ? ' ' + className : '');

  return (
    <>
      <div className={cardStyles.container + ' ' + (scale ? cardStyles.scale : '')} >
        <div>
          <img className={classLine}
               src={apiUrl + card.src}
               alt={card.title}
               onClick={(e) => handle ? handle(card, e) : {}}
               title={card.description}
               {...props}
          />
          {count && <span className={cardStyles.counter}>x{card.count}</span>}
          {<span className={cardStyles.info} onClick={(e) => {
              e.stopPropagation();
              setShowInfo(true)
            }}>&#128065;</span>}
          {card.currentStrong > 0 && card.currentStrong !== card.strong &&
            <span className={cardStyles.strong + ' ' + (card.strong > card.currentStrong ? cardStyles.strongLess : cardStyles.strongMore)}>
              {card.currentStrong}
            </span> }
        </div>
      </div>
      {showInfo && <CardInfo card={card} handler={(e) => {e.stopPropagation(); setShowInfo(false)}}/>}
    </>
  );
}

export function CardInfo({ card, handler})
{
  return (
    <div className={cardStyles.infoContainer} >
      <div>
        <img className={cardStyles.img} alt={card.title} src={apiUrl + card.src}/>
        <span className={cardStyles.infoClose} onClick={handler}>&times;</span>
        {card.currentStrong && card.strong !== card.currentStrong &&
          <span className={cardStyles.strong + ' ' + (card.strong > card.currentStrong ? cardStyles.strongLess : cardStyles.strongMore)}>
            { card.currentStrong}
          </span> }
        <div className={cardStyles.description}>
          <p>{card.description}</p>
        </div>
      </div>
    </div>
  );
}

