import styles from './main.module.css';
import AuthContext from "../Auth/AuthContext";
import {useContext} from "react";
import LoginForm from "../utils/form/LoginForm";
import Dashboard from "./Dashboard";

function Home() {

  const auth = useContext(AuthContext);

  return (
    <>
      <div className={styles.app}>
        {
          null === auth.isGuest ?
            <img src='/app.jpg?v=1' className={styles.img} alt="заставка"/>
            :
            auth.isGuest ?
              <>
                <LoginForm callback={(token) => {
                  auth.setGuest(false, token)
                }}/>
                <img src='/app.jpg?v=1' className={styles.img} alt="заставка"/>
              </>
              :
              <Dashboard/>
        }
      </div>
    </>
  );
}

export default Home;
